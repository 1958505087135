@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* default theme */
  :root {
    /*
      - gray/natural colors for texts, borders and disabled elements.
      - If required we can use as background too.
    */
    /* For rgb(255 255 255 / <alpha-value>) = #ffffff */
    --gray-0: 255 255 255;
    /* For rgb(250 250 250 / <alpha-value>) = #fafafa */
    --gray-50: 250 250 250;
    /* For rgb(241 241 241 / <alpha-value>) = #f1f1f1 */
    --gray-100: 241 241 241;
    /* For rgb(227 227 227 / <alpha-value>) = #e3e3e3 */
    --gray-200: 227 227 227;
    /* For rgb(223 223 223 / <alpha-value>) = #dfdfdf */
    --gray-300: 223 223 223;
    /* For rgb(17 132 60 / <alpha-value>) = #03703C */
    --gray-sentry: 39 40 47;

    /* ----------------------------------- */
    /* primary/brand colors */
    /* ----------------------------------- */
    /* For rgb(215 227 254 / <alpha-value>) = #D7E3FE */
    --primary-lighter: 215 227 254;
    /* For rgb(96 142 251 / <alpha-value>) = #608EFB */
    --primary-light: 96 142 251;
    /* For rgb(56 114 250 / <alpha-value>) = #3872FA */
    --primary-default: 56 114 250;
    /* For rgb(29 88 216 / <alpha-value>) = #1d58d8 */
    --primary-dark: 29 88 216;

    /* ----------------------------------- */
    /* secondary colors */
    /* ----------------------------------- */
    /* For rgb(227 215 252 / <alpha-value>) = #e3d7fc */
    --secondary-lighter: 227 215 252;
    /* For rgb(138 99 210 / <alpha-value>) = #8a63d2 */
    --secondary-light: 138 99 210;
    /* For rgb(121 40 202 / <alpha-value>) = #7928ca */
    --secondary-default: 121 40 202;
    /* For rgb(76 40 137 / <alpha-value>) = #4c2889 */
    --secondary-dark: 76 40 137;

    /* ----------------------------------- */
    /* red/error colors */
    /* ----------------------------------- */
    /* For rgb(247 212 214 / <alpha-value>) = #f7d4d6 */
    --red-lighter: 247 212 214;
    /* For rgb(255 26 26 / <alpha-value>) = #ff1a1a */
    --red-light: 255 26 26;
    /* For rgb(238 0 0 / <alpha-value>) = #e00 */
    --red-default: 238 0 0;
    /* For rgb(197 0 0 / <alpha-value>) = #c50000 */
    --red-dark: 197 0 0;

    /* ----------------------------------- */
    /* orange/warning colors */
    /* ----------------------------------- */
    /* For rgb(255 239 207 / <alpha-value>) = #ffefcf */
    --orange-lighter: 255 239 207;
    /* For rgb(247 185 85 / <alpha-value>) = #f7b955 */
    --orange-light: 247 185 85;
    /* For rgb(245 166 35 / <alpha-value>) = #f5a623 */
    --orange-default: 245 166 35;
    /* For rgb(171 87 10 / <alpha-value>) = #ab570a */
    --orange-dark: 171 87 10;

    /* ----------------------------------- */
    /* blue/info colors */
    /* ----------------------------------- */
    /* For rgb(233 236 239 / <alpha-value>) = #e9ecef */
    --blue-lighter: 233 236 239;
    /* For rgb(52 58 64 / <alpha-value>) = #343A40 */
    --blue-light: 52 58 64;
    /* For rgb(0 112 243 / <alpha-value>) = #0070f3 */
    --blue-default: 0 112 243;
    /* For rgb(33 37 41 / <alpha-value>) = #212529 */
    --blue-dark: 33 37 41;
    /* For rgb(17 72 130 / <alpha-value>) = #114882 */
    --blue-sentry: 17 72 130;

    /* ----------------------------------- */
    /* green/success colors */
    /* ----------------------------------- */
    /* For rgb(185 249 207 / <alpha-value>) = #b9f9cf */
    --green-lighter: 185 249 207;
    /* For rgb(28 203 92 / <alpha-value>) = #06C167 */
    --green-light: 6 193 103;
    /* For rgb(17 168 73 / <alpha-value>) = #048848 */
    --green-default: 4 136 72;
    /* For rgb(17 132 60 / <alpha-value>) = #03703C */
    --green-dark: 3 112 60;

    /* ----------------------------------- */
    /* Font */
    /* ----------------------------------- */
    --font-title: 'Raleway';
    --font-text: 'Lato', sans-serif;
  }

  /* base style */
  html {
    @apply h-full antialiased;
  }

  #player {
    @apply dark:bg-gray-50;
  }

  body {
    @apply flex min-h-full flex-col overflow-x-hidden text-sm text-gray-600 dark:bg-gray-50;
  }

  input::-ms-clear,
  input::-ms-reveal {
    @apply hidden;
  }

  input[type='search']::-webkit-search-cancel-button {
    @apply hidden;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    @apply m-0 appearance-none;
  }

  input[type='checkbox']:checked {
    @apply bg-none;
  }

  /* typography style for the Text component */
  h4,
  h5,
  h6 {
    @apply font-title font-bold dark:text-gray-100;
  }

  h1,
  h2,
  h3, {
    @apply font-title font-black dark:text-gray-100;
  }

  h1 {
    @apply text-3xl dark:text-gray-100 2xl:text-[32px] 4xl:text-4xl;
  }

  h2 {
    @apply text-2xl dark:text-gray-100 2xl:text-[26px] 4xl:text-3xl;
  }

  h3 {
    @apply text-xl 2xl:text-[22px] 4xl:text-2xl;
  }

  h4 {
    @apply text-lg 2xl:xl:text-xl;
  }

  h5 {
    @apply text-base 2xl:text-lg;
  }

  h6 {
    @apply text-sm 2xl:text-base;
  }

  q {
    @apply text-lg;
  }

  /* p, a {
    @apply font-text;
  } */

  text {
    @apply font-title;
  }
}

/* ................................................... */
/* Change autocomplete styles in WebKit */
/* ................................................... */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: currentColor;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
input.searchbar {
  border-radius: 5px;
  border: none;
  color: #333 !important;
}
input::placeholder,
textarea::placeholder {
  color: #333 !important;
}

.image-background {
  background-image: url(/public/images/404.webp);
  background-size: cover;
  background-position: 50% 15%;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
}
.heading-block:after {
  content: '';
  display: block;
  width: 40px;
  border-top: 2px solid;
  margin: 30px auto 0;
}
.capitalize {
  text-transform: uppercase;
}
.blue-link {
  color: #3399ff;
}
a.blue-link svg {
  display: inline;
}
.dark .blue-link:hover {
  color: #fff;
}
.light .blue-link:hover {
  color: #4b5563;
}
.yellow-icon {
  color: #ffc34d;
}
.signin-button,
a button.rizzui-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  margin: 5px 0;
  padding: 0 22px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  background-color: #3399ff;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 3px;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}
.signin-button {
  padding: 0 14px;
  font-size: 11px;
  height: 28px;
  line-height: 28px;
  background-color: #3399ff;
}
.signin-button:hover,
a button.rizzui-button:hover {
  color: #fff !important;
  background-color: #444 !important;
}
.label {
  padding: 5px 9px;
  border-radius: 3px;
  background-color: #dfdfdf;
  color: #333;
}
.dark .label {
  background-color:#444;
  color: #fff;
}

#top-menu {
  margin: 45px 0 0;
  padding: 0;
  /* background: #3399ff; */
  background: #3399ff url(assets/images/bmc-bg.jpg) no-repeat center;
  /* background-size: cover; */
}
header {
  background: none !important;
}
#top-bar {
	position: relative;
	font-size: 13px;
  font-family: var(--font-text);
  background-color: #fff;
  margin-top: -45px;
}
.top-links {
	position: relative;
	flex: 0 0 auto;
	flex-grow: 0;
}
.top-links-container {
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	list-style: none;
	margin-bottom: 0;
}
.top-links-item {
	position: relative;
}
.top-links ul li {
  float: left;
  position: relative;
  height: 44px;
  border-left: 1px solid #eee;
}
.top-links-item:first-child,
.top-links-sub-menu .top-links-item { border-left: 0 !important; }
.top-links-item img {
  display: inline-block;
}
.top-links-item > a {
	display: block;
	padding: 12px;
	font-size: 0.75rem;
	line-height: 20px;
	font-weight: 600;
	text-transform: uppercase;
	color: #666;
}
#top-menu a.items-center {
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 1px;
  text-shadow: 1px 1px 1px rgba(0,0,0,.1);
  text-transform: uppercase;
  font-family: Raleway, sans-serif;
}
#top-menu a.items-center:hover {
  color: rgba(255,255,255,0.9);
}
#top-bar li.current a,
#top-bar .top-links li:hover a {
  color: #3399ff;
}
.top-links li:hover {
  background-color: #eee;
}
#top-bar li.current a:before {
  content: "";
  position: absolute;
  bottom: -1px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 5px solid #3399ff;
  left: 40%;
}
span.releaseDate {
  display: block;
  opacity: .5;
}